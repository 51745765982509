import { format } from "date-fns";
import { ru } from "date-fns/locale/index.js";
import { enGB } from "date-fns/locale/index.js";
import { uz } from "date-fns/locale/index.js";

export const useFormattedDate = () => {
  const { locale } = useI18n();
  const localePicker = {
    ru,
    en: enGB,
    uz,
  };
  function formatDateWithSuffix(date) {
    const dayOfMonth = `${date.getDate()}`;

    const formattedDate = format(date, `LLLL`, {
      locale: localePicker[locale.value],
    });

    return `${dayOfMonth} ${formattedDate}`;
  }

  return formatDateWithSuffix;
};
